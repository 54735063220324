import { enableProdMode, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { bootstrapApplication } from '@angular/platform-browser';
import { TextsControllerService, BooksControllerService, ManageControllerService, DrawerControllerService, StatisticsControllerService, SimilarsControllerService, PredsControllerService, Word2VecControllerService, HHControllerService } from './app/services/WebApi.service.generated';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TokenSendService } from './app/services/token-send.service';
import { IonicModule } from '@ionic/angular';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app/app.routes';


if (environment.production) {
  enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));

bootstrapApplication(AppComponent, {
  providers:[
    TextsControllerService,
    BooksControllerService,
    ManageControllerService,
    DrawerControllerService,
    StatisticsControllerService,
    SimilarsControllerService,
    PredsControllerService,
    Word2VecControllerService,
    HHControllerService,
    provideAnimations(),
    importProvidersFrom(HttpClientModule),
    {
     provide: HTTP_INTERCEPTORS,
      useClass: TokenSendService,
      multi: true
    },
    importProvidersFrom(IonicModule.forRoot({})),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
  ]
});
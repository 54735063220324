import { Component } from '@angular/core';
import { IonicModule, MenuController } from '@ionic/angular';
import { CommonService } from './services/common.service';
import { ManageControllerService, MenuLineDTO } from './services/WebApi.service.generated';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, RouterModule]
})
export class AppComponent {
  menus: MenuLineDTO[];
  constructor(
    public myapi: CommonService,
    private menuCtrl: MenuController,
    private manageCtrl: ManageControllerService
  ) {
    this.initializeApp();
  }



  initializeApp() {
    this.GetMenu();
  }

  toggleMenu() {
    this.menuCtrl.toggle('first');
  }

  GetMenu() {
    this.manageCtrl.getMenu().subscribe({
      next: resp => {
        this.menus = resp.list;
        console.log('GetMenu : ', resp);
        this.myapi.setCookie("token", resp.token, 1000);
        this.menuCtrl.enable(true, 'first');
      }, error: err => {
        console.log('GetMenu ERROR: ', err);
      }
    });
  }
}
